import { useIsMountedRef } from '@kaliber/use-is-mounted-ref'
import { useReportError } from '/machinery/ReportError'
import { handleResponse } from '/machinery/handleResponse'
import { useTranslate } from '/machinery/I18n'
import styles from './Spotify.css'

export function Spotify({ spotifyUrl }) {
  const reportError = useReportError()
  const { __ } = useTranslate()
  const isMountedRef = useIsMountedRef()
  const [iFrame, setIFrame] = React.useState(null)
  const params = (new URLSearchParams({ url: spotifyUrl })).toString()

  React.useEffect(() => {
    if (isMountedRef.current) {
      window.fetch(`https://open.spotify.com/oembed?${params}`)
        .then(handleResponse).then(x => { setIFrame(x.html) })
        .catch(e => { setIFrame(__`spotify-error`); reportError(e) })
    }
  },
  [isMountedRef, params, __, reportError])

  return <div className={styles.component} dangerouslySetInnerHTML={{ __html: iFrame }} />
}
